 .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: 
        linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)), 
        url('https://i.imgur.com/BoqJNUS.png'); 
    background-repeat: repeat;
}

.home__content {
    padding: 2rem 0;
}

.name {
    text-align: center;
    margin: 2rem 0;
}

.name__main {
    font-family: 'Times New Roman', serif;
    font-size: 30px;
}

#author {
    font-family: "Berkshire Swash", serif;
    font-style: normal;
}

.name__sub {
    text-decoration: overline;
    font-size: 18px;
}

/* Skills ------------------------------ */

.skills {
    text-align: center;
    margin-bottom: 2rem;
}

.skill-icon__container {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
    /* border: 2px solid red; */
}

.skill-icon img {
    height: 36px;
    width: 36px;
}

@media (max-width: 800px) {
    .skill-icon__container {
        width: 100%;
    }
}

/* QUOTE ----------------------------- */

.quote {
    text-align: center;
    margin: 1rem 0;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 2rem;
}

.quote span {
    display: block;
}

.quote span:nth-child(1) {
    font-size: 1.25rem;
    overflow: hidden; /* Ensures the content is not revealed until the animation */


}

@media (min-width: 800px) {
    .quote span:nth-child(1) {
        white-space: nowrap; /* Keeps the content on a single line */
        margin: 0 auto; /* Gives that scrolling effect as the typing happens */
        letter-spacing: .15em; /* Adjust as needed */
        animation: 
          typing 3.5s steps(40, end),
          blink-caret .75s step-end 5s;
    }
}

  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }
