
.sidebar {
    text-align: center;
    width: 25rem;
    position: sticky;
    top: 0px;
    max-height: 100svh;
    padding-top: 2rem;
    border-left: 1px solid white;
    border-right: 1px solid white;
    background-color: black;
    min-height: fit-content;
}

/* Avatar & Socials ------------------ */

.avatar {
    max-height: 150px;
    border-radius: 50%;
    transition: .2s;
    border: 5px solid white;
    transition: all .2s;
}

.avatar:hover {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    border: 5px solid #00CCCC;
  }
  
  @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }

.sidebar__name {
    display: block;
    margin: .5rem 0;
    font-size: 1.5rem;
}

.sidebar a {
    text-decoration: none;
    color: white;
}

.social-icons {
    display: flex;
    justify-content: space-around;
    width: 280px;
    margin: 1rem auto;
}

.social-icons__icon {
    max-height: 35px;
    cursor: pointer;
    border: 3px solid white;
    border-radius: 50%;
}

.social-icons__link:hover img {
    opacity: 1;
    -webkit-animation: flash 1s;
    animation: flash 1s;
}

.social-icons__link:hover .social-icons__icon {
    border: 3px solid #00CCCC;
}

/* NAVBAR -------------------------- */
@-webkit-keyframes flash {
    0% {
        opacity: .4;
    }
    100% {
        opacity: 1;
    }
}
@keyframes flash {
    0% {
        opacity: .4;
    }
    100% {
        opacity: 1;
    }
}

.navigation {
    display: flex;
    flex-direction: column;
}

.navigation__link,
.navigation__link *,
.navigation__link :after,
.navigation__link :before,
.navigation__link:after,
.navigation__link:before {
    border: 0 solid;
    box-sizing: border-box;
}

.navigation__link {
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: button;
    background-color: #000;
    background-image: none;
    background-color: red;
    color: #fff;
    cursor: pointer;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
        Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
        Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 100%;
    line-height: 1.5;
    margin: 0;
    -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
    padding: 0;
}

.navigation__link:disabled {
    cursor: default;
}

.navigation__link:-moz-focusring {
    outline: auto;
}

.navigation__link svg {
    display: block;
    vertical-align: middle;
}

.navigation__link [hidden] {
    display: none;
}

.navigation__link {
    box-sizing: border-box;
    display: block;
    font-weight: 900;
    -webkit-mask-image: none;
    padding: 2rem 5rem;
    perspective: 800px;
    position: relative;
    text-transform: uppercase;
    transform-style: preserve-3d;
}

.navigation__link span {
    color: white;
    font-size: 2rem;
    display: grid;
    inset: 0;
    place-items: center;
    position: absolute;
    transform: rotateX(0deg);
    transform-origin: top center;
    transition: 0.2s;
    border-bottom: 1px solid white;
}

.navigation__link span:first-child {
    border-top: 1px solid white;
}

/* Home */
.navigation__link:nth-child(1) span {
    background-image: linear-gradient(rgba(70, 97, 215, 0.5), rgba(70, 97, 215, 0.5)),
        url('https://images.theconversation.com/files/393210/original/file-20210401-13-z6rl6z.jpg?ixlib=rb-4.1.0&rect=0%2C292%2C3008%2C1504&q=45&auto=format&w=1356&h=668&fit=crop');
    background-size: cover;
    background-position-y: 50%;


}

/* Experience */
.navigation__link:nth-child(2) span {
    background-image: linear-gradient(rgba(0, 0, 255, 0.1), rgba(0, 0, 255, 0.1)),
        url('https://i.imgur.com/a7iI8HM.png');
    background-size: cover;
    background-position-y: 30%;
}

/* Projects */
.navigation__link:nth-child(3) span {
    background-image: linear-gradient(rgba(40, 42, 52, 0.8), rgba(40, 42, 52, 0.9)),
        url('https://www.in2code.de/fileadmin/_processed_/0/b/csm_code_javascript_deb0c3970a.jpg');
    background-size: 150%;
    background-position-y: 50%;
    background-position-x: 50%;
}

/* Interests */
.navigation__link:nth-child(4) span {
    background-image: linear-gradient(rgba(40, 42, 52, 0.7), rgba(40, 42, 52, 0.7)),
        url('https://i.imgur.com/5GaLHsF.png');
    background-size: cover;
    background-position-y: 50%;
}

.navigation__link:hover span {
    transform: rotateX(35deg);
}

.navigation__link:after,
.navigation__link:before {
    background: #ddd;
    background: black;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotateX(0deg);
    width: 100%;
    z-index: -1;
}

.navigation__link:after {
    background: #ccc;
    width: 0;
}

/* .navigation__link:hover:after {
-webkit-animation: progress-bar 1.2s;
animation: progress-bar 1.2s;
} */

@-webkit-keyframes progress-bar {
0% {
    opacity: 1;
    width: 0;
}

10% {
    opacity: 1;
    width: 15%;
}

25% {
    opacity: 1;
    width: 25%;
}

40% {
    opacity: 1;
    width: 35%;
}

55% {
    opacity: 1;
    width: 75%;
}

60% {
    opacity: 1;
    width: 100%;
}

to {
    opacity: 0;
    width: 100%;
}
}

@keyframes progress-bar {
0% {
    opacity: 1;
    width: 0;
}

10% {
    opacity: 1;
    width: 15%;
}

25% {
    opacity: 1;
    width: 25%;
}

40% {
    opacity: 1;
    width: 35%;
}

55% {
    opacity: 1;
    width: 75%;
}

60% {
    opacity: 1;
    width: 100%;
}

to {
    opacity: 0;
    width: 100%;
}
}

.spotify {
    width: 95%;
    margin: 2rem auto;
}