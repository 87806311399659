@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Funnel+Display:wght@300..800&display=swap');

*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::_wekbit-scrollbar {
  display: none;
}

body {
  font-family: "Funnel Display", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  position: relative;

}

.app {
  min-height: 787px;
  display: flex;
  justify-content: center;
  color: white;
  background-color: #e5e5f7;

  width: 100%;
  height: 100svh;
  --s: 200px; /* control the size */
  --c1: #1d1d1d;
  --c2: #4e4f51;
  --c3: #3c3c3c;

  background: repeating-conic-gradient(
        from 30deg,
        #0000 0 120deg,
        var(--c3) 0 180deg
      )
      calc(0.5 * var(--s)) calc(0.5 * var(--s) * 0.577),
    repeating-conic-gradient(
      from 30deg,
      var(--c1) 0 60deg,
      var(--c2) 0 120deg,
      var(--c3) 0 180deg
    );
  background-size: var(--s) calc(var(--s) * 0.577);
}

.app__content {
  border-top: none;
  max-width: 2000px;
  display: flex;
  position: relative;
  /* background-color: black; */
}

.page {
  min-width: 700px;
  max-width: 700px;
  padding: 2rem 0;
  position: relative;
  overflow: scroll;
  background-color: gray;
  border-right: 1px solid white;
}

.page__title {
  text-align: center;
  padding-bottom: 1rem;

  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
  Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
  Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 3rem;
}

@media (max-width: 999px) {
  .app-content {
    min-width: 100svw;
    background-color: transparent;
  }

  .page {
    min-width: 95svw;
    border-right: none;
    background: none;
    padding: 1rem;
  }

}

@media (min-width: 1000px) {
  .app-content {
    background-color: black;
  }

  .page {
    background-color: gray;
    background: 
      linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), 
      url('https://i.imgur.com/BoqJNUS.png'); 
     background-repeat: repeat;
  }
}
  