.interests {

}

.letterboxd {
    max-width: 200px;
    border: 1px solid white;
    border-radius: 10px;
    padding: 1rem;
}

.letterboxd img {
    max-height: 4rem;
}

.letterboxd a {
    text-decoration: none;
    color: white;
}

/* External / Social Link Button ----------------- */
.interests__social-btn {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    user-select: none;
    touch-action: manipulation;

    display: block;
    margin-top: 1rem auto 0 auto;
    width: 300px;
    text-align: center;
    text-decoration: none;
   }
   
   .interests__social-btn--shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    background: #008080;
    will-change: transform;
    transform: translateY(2px);
    transition: transform
       600ms
       cubic-bezier(.3, .7, .4, 1);
   }
   
   .interests__social-btn--edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(
       to left,
       hsl(180deg 100% 10%) 0%,
       hsl(180deg 100% 18%) 8%,
       hsl(180deg 100% 18%) 92%,
       hsl(180deg 100% 10%) 100%
     );
   }
   
   .interests__social-btn--front {
    display: block;
    position: relative;
    padding: 12px 27px;
    border-radius: 12px;
    font-size: 1.1rem;
    color: white;
    background: #00B3B3;
    will-change: transform;
    transform: translateY(-4px);
    transition: transform
       600ms
       cubic-bezier(.3, .7, .4, 1);
   }
   
   .interests__social-btn:hover {
    filter: brightness(110%);
   }
   
   .interests__social-btn:hover .interests__social-btn--front {
    transform: translateY(-6px);
    transition: transform
       250ms
       cubic-bezier(.3, .7, .4, 1.5);
   }
   
   .interests__social-btn:active .interests__social-btn--front {
    transform: translateY(-2px);
    transition: transform 34ms;
   }
   
   .interests__social-btn:hover .interests__social-btn--shadow {
    transform: translateY(4px);
    transition: transform
       250ms
       cubic-bezier(.3, .7, .4, 1.5);
   }
   
   .interests__social-btn:active .interests__social-btn--shadow {
    transform: translateY(1px);
    transition: transform 34ms;
   }
   
   .interests__social-btn:focus:not(:focus-visible) {
    outline: none;
   }