/* mobile-btn ------------------------ */

.mobile-btn__container {
    position: sticky;
    display: flex;
    justify-content: right;
    top: 10px;
    cursor: pointer;
    z-index: 1000;
}

.mobile-btn {
    color: black;
    
    font-size: 2rem;
    text-align: center;
    display: block;
    text-decoration: none;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    transition: all .2s;
}

.mobile-btn:hover {
    background-color: #00CCCC;
    color: white;
}

/* mobile home ----------------------- */

.mobile-home {
    
}

.mobile-link__container {
    display: flex;
    flex-direction: column;
}

.mobile-link,
.mobile-link *,
.mobile-link :after,
.mobile-link :before,
.mobile-link:after,
.mobile-link:before {
    border: 0 solid;
    box-sizing: border-box;
}

.mobile-link {
    background-color: #000;
    color: #fff;
    cursor: pointer;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
        Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
        Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 100%;
    line-height: 2rem;
    font-size: 2rem; 
    padding: 0;
    display: block;
    font-weight: 900;
    padding: 2rem 5rem;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    border-bottom: 1px solid white;
}

.mobile-link:first-child{
    border-top: 1px solid white;
}