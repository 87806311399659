.card {
    background-color: black;
    /* box-shadow:  -2px -2px 2px black; */
    border-radius: 1rem;
    padding: 1rem;
    max-width: 600px;
    margin: 1rem auto;
    background: 
        linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), 
        url('https://i.imgur.com/BoqJNUS.png'); 
    background-repeat: repeat;
}

.card__image {
    width: 100%;
}

.card__image--interest-container {
    min-height: 350px;
}

.card__title {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 100%;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.card__link {
    text-decoration: none;
    color: white;
    transition: all .3s;
}

.card__link svg {
    font-size: 1.25rem;
    vertical-align: center;
}

.card__link:hover {
    color: #00CCCC;
}